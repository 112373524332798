<template>
  <div>
    <b-modal size="xl" hide-footer v-model="modalProducts" class="w-100" title="Productos">
      <b-row>
        <b-col md="12">
          <b-form-group label="Buscar producto :">
            <b-form-input type="text" v-model="search_product" @keyup="SearchProducts" autofocus></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="table-responsive mt-3">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="3%"  class="text-center align-middle">#</th>
                  <th width="8%"  class="text-center align-middle">Código</th>
                  <th width="45%"  class="text-center align-middle">Nombre</th>
                  <th width="10%"  class="text-center align-middle">Categoria</th>
                  <th width="10%"  class="text-center align-middle">U.M.</th>
                  <th width="10%"  class="text-center align-middle">Cantidad</th>
                  <th width="13%"  class="text-center align-middle">P. Unit</th>
                  <th width="7%"  class="text-center align-middle">Acciones</th>

                </tr>
              </thead>
              <tbody v-for="(item, it) in products" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-center">{{ item.code }}</td>
                 <td class="text-left">{{ item.name + (item.presentation.length == 0 ? '':' - '+item.presentation ) }}</td>
                  <td class="text-left">{{ item.category_name }}</td>
                  <td class="text-center">{{ NameUnitMeasure(item.unit_measure) }}</td>
                  <td class="text-center">
                    <input type="number" step="any" v-model="item.quantity" class="form-control text-right">
                  </td>
                  <td class="text-center">
                    <input type="number" step="any" v-model="item.unit_price" class="form-control text-right">
                  </td>
                  <td class="text-center">
                    <b-button type="button" @click="AddProduct(it)"  variant="primary" class="form-control"><i class="fas fa-plus-square"></i></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<style>


</style>



<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
export default {
  name: "ModalsProduct",
  data() {
    return {
        modalProducts:false,
        module:'Shopping',
        role:0,
        search_product:'',
        products: [],
    };
  },
  created (){
    
  },
  mounted () {
    EventBus.$on('ModalProductsShow', (role) => {
      this.modalProducts = true;
      this.role = role;
    });
    
  },
  methods: {
      SearchProducts,
      AddProduct,
      NameUnitMeasure,
      ...mapActions('ShoppingOrder',['mLoadAddShoppingDetail']),
      ...mapActions('ShoppingOrder',['mLoadTotalsShoppingDetail']),
      
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function NameUnitMeasure(code) {
    return CodeToName.NameUnitMeasure(code);
}

function AddProduct(index) {
    let quantity = this.products[index].quantity;
    let unit_price = this.products[index].unit_price;
    let id_product = this.products[index].id_product;
    if (quantity.length == 0) {
      me.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese una cantidad', type: 'error'})
      return false;
    }
    if (unit_price.length == 0) {
      me.$notify({ group: 'alert', title: 'Sistema', text:'Ingrese un precio unitario', type: 'error'})
      return false;
    }
    let me = this;
    let url = this.url_base + "product/view/" + id_product;
    axios({
      method: "GET",
      url: url,
      headers: { token: this.token, module: this.module, role: this.role},
    })
    .then(function (response) {

       if (response.data.status == 200) {
        
        let total_value = parseFloat(quantity) *  parseFloat(unit_price);
        let detail = {
          id_product : response.data.result.id_product,
          code : response.data.result.code,
          name : response.data.result.name,
          presentation : response.data.result.presentation,
          unit_measure : response.data.result.name_unit_measure,
          igv : response.data.result.igv,
          quantity : parseFloat(quantity).toFixed(2),
          percentage_discount: (0).toFixed(2),
          package: (1).toFixed(0),

          unit_value: parseFloat(unit_price).toFixed(5),
          unit_discount: (0).toFixed(5),
          net_unit_value: parseFloat(unit_price).toFixed(5),
          unit_igv: (0).toFixed(5),
          unit_price: (0).toFixed(5),

          total_value: (total_value).toFixed(5),
          total_discount: (0).toFixed(5),
          net_total_value: (total_value).toFixed(5),
          total_igv: (0).toFixed(5),
          total_price: (0).toFixed(5),
        }

        me.mLoadAddShoppingDetail(detail);
        me.mLoadTotalsShoppingDetail();
        me.$notify({ group: 'alert', title: 'Sistema', text:'Se ha agregado el producto '+ detail.name, type: 'success'});
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }

     
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });

   
}

//Buscar productos
function SearchProducts() {
  let me = this;
  let search = this.search_product == "" ? "all" : this.search_product;
  let url = this.url_base + "product/search";
  let data = {
    search : search,
    type_stock : 1,
  };
  axios({
    method: "POST",
    url: url,
    data:data,
    headers: { token: this.token, module: this.module, role: this.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.products = response.data.result;
      } else {
        me.products = [];
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      
    });
}
</script>
